import Vue from "vue";
import Log from "../../../components/Log/Log";
import { mapGetters } from "vuex";
import { PAGES } from "../../../Stores/state";
import { PAGE_CURRENTPAGE_GETTER } from "../../../Stores/getter";
import {
  getSyntaxConfigsByPageId,
  getSyntaxConfigById,
  updateSyntaxConfig,
  createSyntaxConfig,
  deleteSyntaxConfig,
} from "../../../Services/SocService";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";

export default Vue.extend({
  name: "ConfigSystem",
  components: { Log, ConfirmDialog },
  childInterface: {
    onShow: () => {},
  },
  data: function() {
    return {
      headers: dataConfig,
      items: [],
      totalCount: 0,
      pageIndex: 1,
      pageSize: 5,
      isLoading: true,
      isOpenDialog: false,
      isDisable: false,
      rules: [(v) => !!v || "Rquired"],
      syntaxModel: {
        id: null,
        key: null,
        value: null,
        relativeKeys: null,
      },
      keys: [],
      filterKeys: [],
      selectedKeys: [],
      isShowHint: false,
      vaRef: "#Reference#",
    };
  },
  mounted: function() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let self = this;
      if (self.pageSize === -1) self.pageSize = self.totalCount;

      getSyntaxConfigsByPageId(
        this.page.id,
        this.pageIndex,
        this.pageSize
      ).then((res) => {
        const {
          data: { data, paged },
        } = res;
        self.totalCount = paged.totalCount;
        self.items = data;
        self.isLoading = false;
        self.keys = data.map((s) => s.key);
      });
    },
    loadItemUpdate(id) {
      this.toggleDialog();
      this.selectedKeys = [];
      getSyntaxConfigById(id).then((res) => {
        const { data } = res.data;
        this.syntaxModel = data[0];
        this.filterKeys = this.keys.filter((k) => k !== this.syntaxModel.key);

        if (this.syntaxModel.relativeKeys !== null) {
          const keys = this.syntaxModel.relativeKeys.split(",");
          this.selectedKeys = keys;
        }
      });
    },
    async submitItem() {
      const { id } = this.syntaxModel;
      if (this.selectedKeys.length !== 0) {
        this.syntaxModel.relativeKeys = this.selectedKeys.join(",");
      }

      if (id === null) {
        await createSyntaxConfig(this.syntaxModel);
      } else {
        await updateSyntaxConfig(this.syntaxModel);
      }
      await this.fetchData();
      this.toggleDialog();
    },
    async deleteItem({ id, key }) {
      this.onShowDialog({ id, key });
    },
    toggleDialog() {
      this.isOpenDialog = !this.isOpenDialog;
    },
    toggleShowHint() {
      this.isShowHint = !this.isShowHint;
    },
    onCreateKey(val) {
      if (this.page.id === undefined) {
        this.$toasted.show(`Please choose a page`, {
          theme: "bubble",
          position: "top-center",
          duration: 3000,
          fitToScreen: true,
        });
        return;
      }

      this.syntaxModel = {
        id: null,
        key: null,
        relativeKeys: null,
        value: val,
        pageId: this.page.id,
      };

      this.selectedKeys = [];

      if (val === this.vaRef) {
        this.isDisable = true;
      } else {
        this.isDisable = false;
      }

      this.toggleDialog();
    },
    keyRule(v) {
      const re = new RegExp("^!\\w+");
      if (!re.test(v)) return 'Append "!" the first key and not space in key';
      if (/\s/.test(v)) return "Not space in key";
      return true;
    },
    async onDialogHandler(value) {
      const { id, isSuccess, key } = value;
      if (!isSuccess) return;
      await deleteSyntaxConfig(id);
      const message = `Bạn vừa xoá key: ${key}`;
      this.$toasted.show(message, {
        theme: "bubble",
        position: "top-center",
        duration: 2000,
        fitToScreen: true,
      });
      await this.fetchData();
    },
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    onShowDialog(syntax) {
      const dialog = {
        title: "Do you want to delete it?",
        content: `SyntaxConfig: ${syntax.key}`,
        data: {
          id: syntax.id,
          key: syntax.key,
        },
      };
      this.$options.childInterface.onShow(dialog);
    },
  },
  watch: {
    pageSize: function() {
      this.fetchData();
    },
    pageIndex: function() {
      this.fetchData();
    },
  },
  computed: {
    ...mapGetters(PAGES, { page: PAGE_CURRENTPAGE_GETTER }),
  },
});

const dataConfig = [
  {
    value: "id",
    text: "Id",
    width: "20%",
    sortable: false,
  },
  {
    value: "key",
    text: "Key",
    width: "15%",
    sortable: false,
  },
  {
    value: "value",
    text: "Value",
    width: "55%",
    sortable: false,
  },
  {
    text: "Action",
    width: "15%",
    value: "action",
    sortable: false,
  },
];
