import axios from "axios";
import Mgr from "./SecuriyService";
import { routes } from "../config";
import Vue from "../main";

// const token =
// "eyJhbGciOiJSUzI1NiIsImtpZCI6Ikg5dFZJRUxvQTFaTHhUY0NSVVhSaUEiLCJ0eXAiOiJhdCtqd3QifQ.eyJuYmYiOjE2NTM1Nzc0ODEsImV4cCI6MTY1MzU4MTA4MSwiaXNzIjoiaHR0cHM6Ly9pZHAubW5saWZlYmxvZy5jb20iLCJhdWQiOlsiYmxvZ1NlcnZpY2UiLCJjb25zdHJ1Y3Rpb25TZXJ2aWNlIiwiaWRwU2VydmljZSIsIm5ld3NTZXJ2aWNlIiwic29jU2VydmljZSJdLCJjbGllbnRfaWQiOiJjbGllbnQiLCJyaWdodHMiOiJbXCJjcmVhdGVfdXNlclwiLFwiY3JlYXRlX3JlYWN0aW9uVHJhY2tpbmdzXCIsXCJkZWxldGVfcmVhY3Rpb25UcmFja2luZ3NcIixcInVwZGF0ZV9yZWFjdGlvblRyYWNraW5nc1wiLFwicmVhZF9yZWFjdGlvblRyYWNraW5nc1wiLFwiZGVsZXRlX3N5bnRheENvbmZpZ1wiLFwidXBkYXRlX3N5bnRheENvbmZpZ1wiLFwicmVhZF9zeW50YXhDb25maWdcIixcImNyZWF0ZV9zeW50YXhDb25maWdcIixcImNyZWF0ZV9jb21tZW50XCIsXCJ1cGRhdGVfYmxvZ1wiLFwicmVtb3ZlX2Jsb2dcIixcInZpZXdfYmxvZ1wiLFwiY3JlYXRlX2Jsb2dcIixcInZpZXdfY29tbWVudFwiLFwidXBkYXRlX2NvbW1lbnRcIixcInJlbW92ZV9jb21tZW50XCIsXCJzZWFyY2hfY29tbWVudFwiLFwiY3JlYXRlX2ltYWdlXCIsXCJ2aWV3X2ltYWdlXCIsXCJjcmF3X25ld3NcIixcImNyZWF0ZV9uZXdzXCIsXCJyZWFkX25ld3NcIixcInVwZGF0ZV9uZXdzXCIsXCJkZWxldGVfbmV3c1wiLFwiY3JlYXRlX3N5c3RlbWNvbmZpZ1wiLFwicmVhZF9zeXN0ZW1jb25maWdcIixcInVwZGF0ZV9zeXN0ZW1jb25maWdcIixcImRlbGV0ZV9zeXN0ZW1jb25maWdcIl0iLCJzY29wZSI6WyJibG9nU2VydmljZSIsImNvbnN0cnVjdGlvblNlcnZpY2UiLCJpZHBTZXJ2aWNlIiwibmV3c1NlcnZpY2UiLCJzb2NTZXJ2aWNlIl19.QOoKyU-4z3gMhQsuSm-99ONc1xcTWbLUjwuUp18-VjvkUfeuSNdLM_VzAIeilRxh2DcanfeVxf89JrjvWg39VZ1XGPUewwzu71ZuSF7AMCThedS4zGXc9-BQDyPoXp8FW-k2QETlgqa7AYUVXoPM5viwQEoWgvXT_FsJh7yQvW345gJyQ_UiVMuHQ2z-X6zaIyxatobm5Z9GXRqBZ_XTvosab7onqJjoqYDtKz4bkVEceHq2FFuaQd85l5qBt_6z2V-hY5W_XRmTmRhC2ZnJSzu2q9HB3hVpGB7hpY1f9nwrb9uH66cJf9DzsMozoCQCHu9iJxTUB6HoEfN2h0_gEQ";
const user = new Mgr();

async function defineHeaderAxios() {
  await user.getAcessToken().then(
    (accessToken) => {
      axios.defaults.headers.common["Authorization"] = "Bearer " + accessToken;
    },
    (err) => {
      console.log(err);
    }
  );
  // axios.defaults.headers.common["Authorization"] = "Bearer " + token;
}

const Axios = async (config) => {
  await defineHeaderAxios();
  return axios(config);
};
export default Axios;

axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    try {
      switch (error.response.status) {
        case 401:
          UnAuthorize();
          break;
        case 403:
          Forbidden();
          break;
        case 400:
          HandlerError(error);
          break;
        default:
          return Promise.reject(error);
      }
    }
    catch (err) {
      HandlerForceError(error);
    }
  }
);
function UnAuthorize() {
  user.signOut();
}

function Forbidden() {
  window.location = `/#${routes.guest.forbidden}`;
}

function HandlerError(err) {
  const { message } = err.response.data;
  Vue.$toasted.show(message, {
    theme: "bubble",
    position: "top-center",
    duration: 3000,
    fitToScreen: true,
  });
}

function HandlerForceError(message) {
  const error = {
    response: {
      data: {
        message
      }
    }
  }
  HandlerError(error);
}
