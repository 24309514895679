<template>
  <v-container fluid>
    <v-row>
      <app-header></app-header>
      <router-view></router-view>
    </v-row>
  </v-container>
</template>

<script>
import AppHeader from "../../Shared/Header/Header.vue";

export default {
  name: "Admin",
  components: { AppHeader },
  data: () => ({
    //
  }),
};
</script>
