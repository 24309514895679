import axios from "./AxiosService";
import { URL_SOC_SERVICE, URL_SOC_SERVICE_IMAGES } from "../config";

const METHOD_GET = "GET";
const METHOD_PUT = "PUT";
const METHOD_DELETE = "DELETE";
const METHOD_POST = "POST";
const PAGE_INDEX_DEFAULT = 1;
const PAGE_SIZE_DEAFULT = 100000000;

export const getEventLogs = async (pageIndex, pageSize) => {
  return axios({
    method: METHOD_GET,
    baseURL: URL_SOC_SERVICE,
    url: "/transactionlog",
    params: {
      pageIndex,
      pageSize,
      sort: '{"key": "CreatedUtc", "criteria":"desc"}',
    },
  });
};

export const getSyntaxConfigs = (pageIndex, pageSize) => {
  const sorts = `{"key": "CreatedUtc", "criteria":"desc"}`;
  return getBaseSyntaxConfigs(pageIndex, pageSize, null, sorts);
};

export const getSyntaxConfigsByPageId = (pageId, pageIndex, pageSize) => {
  const query = `{"key":"pageId","operate": "eq" ,"value":"${pageId}"}`;
  const sorts = `{"key": "CreatedUtc", "criteria":"desc"}`;
  return getBaseSyntaxConfigs(pageIndex, pageSize, query, sorts);
};

export const getSyntaxConfigRefsByPageId = (pageId, pageIndex, pageSize) => {
  const query = `{"and":[{"key":"pageId","operate": "eq" ,"value":"${pageId}"}, {"key":"value","operate": "eq" ,"value":"#Reference#"}]}`;
  const sorts = `{"key": "CreatedUtc", "criteria":"desc"}`;
  return getBaseSyntaxConfigs(pageIndex, pageSize, query, sorts);
};

export const getSyntaxConfigByKey = (key) => {
  const query = `{"key":"key","operate": "eq" ,"value":"${key}"}`;
  return getBaseSyntaxConfigs(1, 20, query);
};

export const getSyntaxConfigById = (id) => {
  const query = `{"key":"id","operate": "eq" ,"value":"${id}"}`;
  return getBaseSyntaxConfigs(1, 20, query);
};

export const updateSyntaxConfig = async ({ id, key, relativeKeys, value }) => {
  return axios({
    method: METHOD_PUT,
    baseURL: URL_SOC_SERVICE,
    url: `syntaxconfigs/${id}`,
    data: {
      id,
      key,
      relativeKeys,
      value,
    },
  });
};

export const createSyntaxConfig = async ({
  id,
  key,
  relativeKeys,
  value,
  pageId,
}) => {
  return axios({
    method: METHOD_POST,
    baseURL: URL_SOC_SERVICE,
    url: "syntaxconfigs",
    data: {
      id,
      key,
      relativeKeys,
      value,
      pageId,
    },
  });
};

export const deleteSyntaxConfig = async (id) => {
  return axios({
    method: METHOD_DELETE,
    baseURL: URL_SOC_SERVICE,
    url: `syntaxconfigs/${id}`,
  });
};

const getBaseSyntaxConfigs = async (pageIndex, pageSize, filters, sorts) => {
  return axios({
    method: METHOD_GET,
    baseURL: URL_SOC_SERVICE,
    url: "syntaxconfigs",
    params: {
      pageIndex,
      pageSize,
      filters,
      sorts,
    },
  });
};
export const getUsers = (pageIndex, pageSize) =>
  getBaseUsers(pageIndex, pageSize);

const getBaseUsers = async (pageIndex, pageSize, filters, sorts) => {
  return axios({
    method: METHOD_GET,
    baseURL: URL_SOC_SERVICE,
    url: "users",
    params: {
      pageIndex,
      pageSize,
      filters,
      sorts,
    },
  });
};

export const getUsersByPage = (pageId) => {
  return axios({
    method: METHOD_GET,
    baseURL: URL_SOC_SERVICE,
    url: `users/page/${pageId}`,
  });
};

export const getPageDetail = (pageId) => {
  const query = `{"key":"id","operate": "eq" ,"value":"${pageId}"}`;
  return getPages(1, 20, query);
};

export const getPages = (pageIndex, pageSize, filters, sorts) => {
  return axios({
    method: METHOD_GET,
    baseURL: URL_SOC_SERVICE,
    url: "pages",
    params: {
      pageIndex,
      pageSize,
      filters,
      sorts,
    },
  });
};

export const createPage = ({ id, name, token, verifyToken }) => {
  return axios({
    method: METHOD_POST,
    baseURL: URL_SOC_SERVICE,
    url: "pages",
    data: { id, name, token, verifyToken },
  });
};

export const deletePage = (pageId) => {
  return axios({
    method: METHOD_DELETE,
    baseURL: URL_SOC_SERVICE,
    url: `pages/${pageId}`,
  });
};

export const deletePost = (pageId) => {
  return axios({
    method: METHOD_DELETE,
    baseURL: URL_SOC_SERVICE,
    url: `posts/${pageId}`,
  });
};

export const updatePost = (pageId, content) => {
  return axios({
    method: METHOD_PUT,
    baseURL: URL_SOC_SERVICE,
    url: `posts/${pageId}`,
    data: {
      content,
    },
  });
};

export const getPostsByPage = (pageIndex, pageSize, pageId) => {
  const query = `{"key":"pageId","operate": "eq" ,"value":"${pageId}"}`;
  const sort = `{"key": "createdUtc", "criteria":"desc"}`;
  return getPosts(pageIndex, pageSize, query, sort);
};

const getPosts = (pageIndex, pageSize, filters, sorts) => {
  return axios({
    method: METHOD_GET,
    baseURL: URL_SOC_SERVICE,
    url: "posts",
    params: {
      pageIndex,
      pageSize,
      filters,
      sorts,
    },
  });
};

export const sendMessageAllUsersOfPage = (pageId, message) => {
  return axios({
    method: METHOD_POST,
    baseURL: URL_SOC_SERVICE,
    url: `messages/pages/${pageId}`,
    data: {
      pageId,
      message,
    },
  });
};

export const createProduct = ({
  name,
  description,
  shortName,
  productTypeId,
  pageId,
  syntaxConfigId,
  dynamicImages,
  productSpecials,
  files,
}) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("description", description);
  formData.append("shortName", shortName);
  formData.append("productTypeId", productTypeId);
  formData.append("pageId", pageId);
  formData.append("syntaxConfigId", syntaxConfigId);
  formData.append("dynamicImages", dynamicImages);
  formData.append("productSpecials", productSpecials);
  formData.append("fiels", files);
  return axios({
    method: METHOD_POST,
    baseURL: URL_SOC_SERVICE,
    url: "products",
    data: formData,
  });
};

export const createProductSimple = ({
  name,
  description,
  shortName,
  pageId,
  syntaxConfigId,
}) => {
  return axios({
    method: METHOD_POST,
    baseURL: URL_SOC_SERVICE,
    url: "products",
    data: {
      name,
      description,
      shortName,
      pageId,
      syntaxConfigId,
      productTypeId: undefined,
      files: [],
      dynamicImages: [],
      productSpecials: [],
    },
  });
};

export const deleteProduct = (id) => {
  return axios({
    method: METHOD_DELETE,
    baseURL: URL_SOC_SERVICE,
    url: `products/${id}`,
  });
};

export const updateProduct = ({
  id,
  name,
  description,
  shortName,
  productTypeId,
}) => {
  const data = { id, name, description, shortName, productTypeId };
  return axios({
    method: METHOD_PUT,
    baseURL: URL_SOC_SERVICE,
    url: `products/${id}`,
    data: data,
  });
};

export const getProductById = (productId) => {
  return axios({
    method: METHOD_GET,
    baseURL: URL_SOC_SERVICE,
    url: `products/${productId}`,
  });
};

export const getSyntaxConfigProducts = (
  pageIndex,
  pageSize,
  filters,
  sorts
) => {
  return axios({
    method: METHOD_GET,
    baseURL: URL_SOC_SERVICE,
    url: "syntaxConfigProducts",
    params: {
      pageIndex,
      pageSize,
      filters,
      sorts,
    },
  });
};

export const getSyntaxConfigProductsBySyntaxId = (syntaxConfigId) => {
  const query = `{"key":"SyntaxConfigId","operate":"eq", "value":"${syntaxConfigId}"}`;
  const sorts = '{"key": "sequence", "criteria":"desc"}';
  return getSyntaxConfigProducts(
    PAGE_INDEX_DEFAULT,
    PAGE_SIZE_DEAFULT,
    query,
    sorts
  );
};

export const getProductImagesByProductId = (productId, pageIndex, pageSize) => {
  const query = `{"key":"productId","operate":"eq","value":"${productId}"}`;
  const sorts = '{"key": "creadUtc", "criteria":"desc"}';
  return getProductImages(pageIndex, pageSize, query, sorts);
};

export const getProductImages = (pageIndex, pageSize, filters, sorts) => {
  return axios({
    method: METHOD_GET,
    baseURL: URL_SOC_SERVICE,
    url: "productImages",
    params: {
      pageIndex,
      pageSize,
      filters,
      sorts,
    },
  });
};

export const swapSyntaxConfigProducts = ({ requestId, targetId }) => {
  return axios({
    method: METHOD_PUT,
    baseURL: URL_SOC_SERVICE,
    url: "syntaxConfigProducts/swap",
    data: {
      requestId,
      targetId,
    },
  });
};

export const createProductImage = ({
  name,
  path,
  display,
  productId,
  file,
}) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("display", display);
  formData.append("path", path);
  formData.append("productId", productId);
  formData.append("file", file);
  return axios({
    method: METHOD_POST,
    baseURL: URL_SOC_SERVICE,
    url: "productImages",
    data: formData,
  });
};

export const deleteProductImage = (id) => {
  return axios({
    method: METHOD_DELETE,
    baseURL: URL_SOC_SERVICE,
    url: `productImages/${id}`,
  });
};

export const updateProductImage = ({
  id,
  name,
  path,
  display,
  productId,
  file,
}) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("display", display);
  formData.append("path", path);
  formData.append("productId", productId);
  formData.append("file", file);
  return axios({
    method: METHOD_PUT,
    baseURL: URL_SOC_SERVICE,
    url: `productImages/${id}`,
    data: formData,
  });
};

export const createProductSpecial = ({
  productId,
  name,
  description,
  shortName,
  unitProduct,
  productPrices,
}) => {
  return axios({
    method: METHOD_POST,
    baseURL: URL_SOC_SERVICE,
    url: `productSpecials`,
    data: {
      productId,
      name,
      description,
      shortName,
      unitProduct,
      productPrices,
    },
  });
};

export const swapProductSpecialMap = ({ requestId, targetId }) => {
  return axios({
    method: METHOD_PUT,
    baseURL: URL_SOC_SERVICE,
    url: "productSpecialMaps/swap",
    data: {
      requestId,
      targetId,
    },
  });
};

export const deleteProductSpecial = (id) => {
  return axios({
    method: METHOD_DELETE,
    baseURL: URL_SOC_SERVICE,
    url: `productspecials/${id}`,
  });
};

export const getProductSpecials = (pageIndex, pageSize, filters, sorts) => {
  return axios({
    method: METHOD_GET,
    baseURL: URL_SOC_SERVICE,
    url: "productSpecials",
    params: {
      pageIndex,
      pageSize,
      filters,
      sorts,
    },
  });
};

const getProductSpecialMaps = (pageIndex, pageSize, filters, sorts) => {
  return axios({
    method: METHOD_GET,
    baseURL: URL_SOC_SERVICE,
    url: "productSpecialMaps",
    params: {
      pageIndex,
      pageSize,
      filters,
      sorts,
    },
  });
};

export const getProductSpecialMapsByProductId = (
  productId,
  pageIndex = PAGE_INDEX_DEFAULT,
  pageSize = PAGE_SIZE_DEAFULT
) => {
  const query = `{"key":"productId","operate":"eq","value":"${productId}"}`;
  const sorts = `{"key": "sequence", "criteria":"desc"}`;
  return getProductSpecialMaps(pageIndex, pageSize, query, sorts);
};

export const toFileFromURL = (imagePath) => {
  return axios({
    method: METHOD_GET,
    baseURL: URL_SOC_SERVICE_IMAGES,
    responseType: "blob",
    url: imagePath,
  });
};

export const clearCacheProductTemplate = (pageId, syntaxConfigKey) => {
  return axios({
    method: METHOD_POST,
    baseURL: URL_SOC_SERVICE,
    url: `products/cache/clear`,
    data: { pageId, syntaxConfigKey },
  });
};
