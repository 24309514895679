import Vue from "vue";
import { URL_SOC_SERVICE_IMAGES, routes } from '../../config';

export default Vue.component('product-card', {
  props: {
    item: null,
    isDisplayActionTop: {
      type: Boolean,
      default: true
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      image: undefined,
      routes,
      isShow: false,
      isValid: true,
      itemEdit: {
        product: {
          id: undefined,
          name: undefined,
          shortName: undefined,
          description: undefined
        }
      },
      requiredRule: v => !!v || "Required"
    }
  },
  mounted: function () {
    this.cloneProductItem();
  },
  methods: {
    onToggle() {
      this.$data.isShow = !this.$data.isShow;
      this.cloneProductItem();
    },
    cloneProductItem() {
      this.$data.itemEdit = JSON.parse(JSON.stringify(this.item));
    },
    onSave() {
      if (!this.$data.isValid) return;
      this.$emit('onUpdateEvent', this.itemEdit);
      this.onToggle();
    },
    validate() {
      this.$refs.form.validate();
    },
  },
  computed: {
    renderImageURL() {
      if (this.item.product.images === null || this.item.product.images === undefined) return;
      const { images } = this.item.product
      const image = images.pop();
      if (image === undefined) return;
      if (image.isDynamic)
        return image.path;

      return `${URL_SOC_SERVICE_IMAGES}/${image.path}`;
    }
  }
})
