import Vue from "vue";
import {
  getProductSpecialMapsByProductId,
  createProductSpecial,
  deleteProductSpecial,
  swapProductSpecialMap,
} from "../../../Services/SocService";
import { routes } from "../../../config";
import ProductSpecialCard from "../../../components/ProductSpecial/Index";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";

export default Vue.extend({
  name: "ProductSpecial",
  components: { ProductSpecialCard, ConfirmDialog },
  childInterface: {
    onShow: () => {},
  },
  data: function() {
    return {
      productId: undefined,
      productSpecialMaps: [],
      isOpenDialog: false,
      productSpecial: undefined,
      rules: [(v) => !!v || "Rquired"],
      formHasErros: false,
      errorMessages: [],
    };
  },
  created() {
    const {
      params: { productId },
    } = this.$route;
    this.productId = productId;
    this.productSpecial = createProductSpecialDefault(productId);
  },
  mounted() {
    if (!this.hasProduct()) return;
    this.initData();
  },
  methods: {
    initData() {
      getProductSpecialMapsByProductId(this.productId).then((res) => {
        const {
          data: { data },
        } = res;
        this.productSpecialMaps = data;
      });
    },
    hasProduct() {
      if (this.productId === undefined) {
        this.$toasted.show(`Please choose a product`, {
          theme: "bubble",
          position: "top-center",
          duration: 3000,
          fitToScreen: true,
        });
        return false;
      }
      return true;
    },
    toggleDialog() {
      this.isOpenDialog = !this.isOpenDialog;
      if (this.isOpenDialog === false) {
        this.productSpecial = createProductSpecialDefault(this.productId);
        this.resetForm();
      }
    },
    resetForm() {
      this.errorMessages = [];
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset();
      });
    },
    validateForm() {
      this.formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
      });
    },
    submit() {
      this.validateForm();
      if (this.formHasErrors) {
        Vue.toasted.show("Form can't submit", {
          theme: "bubble",
          position: "top-center",
          duration: 3000,
          fitToScreen: true,
        });
        return;
      }
      const request = createProductSpecialRequest(this.productSpecial);
      createProductSpecial(request).then(() => {
        this.initData();
      });
      this.toggleDialog();
    },
    onDeleteProductSpecial(id) {
      this.onShowDialog(id);
    },
    onTopProductSpecialMap(requestId, targetIndex) {
      targetIndex--;
      const targetId = this.productSpecialMaps[targetIndex].id;
      this.swap(requestId, targetId);
    },
    swap(requestId, targetId) {
      swapProductSpecialMap({ requestId, targetId }).then((res) => {
        const { data } = res;
        const itemFirst = data[0];
        const itemSecond = data[1];

        this.productSpecialMaps = this.productSpecialMaps
          .map((item) => {
            if (item.id == itemFirst.id) item.sequence = itemFirst.sequence;

            if (item.id == itemSecond.id) item.sequence = itemSecond.sequence;

            return item;
          })
          .sort(itemCompare);
      });
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    backNavigate() {
      const { $router } = this;
      if (window.history.length > 2) $router.back();
      $router.push(routes.admin.home);
    },
    onDialogHandler(value) {
      const { id, isSuccess } = value;
      if (!isSuccess) return;
      deleteProductSpecial(id).then(() => {
        this.productSpecialMaps = this.productSpecialMaps.filter(
          (s) => s.productSpecial.id !== id
        );
      });
    },
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    onShowDialog(id) {
      const dialog = {
        title: "Do you want to delete it?",
        content: `Product Special: ${id}`,
        data: {
          id: id,
        },
      };
      this.$options.childInterface.onShow(dialog);
    },
  },
  computed: {
    form() {
      return {
        name: this.productSpecial.name,
        description: this.productSpecial.description,
        shortName: this.productSpecial.shortName,
        unitProduct: this.productSpecial.unitProduct,
        prdPriceAmount: this.productSpecial.productPrice.amount,
        prdPriceUnitAmount: this.productSpecial.productPrice.unitAmount,
        prdPriceUnitProduct: this.productSpecial.productPrice.unitProduct,
      };
    },
  },
});

const createProductSpecialDefault = (productId = undefined) => {
  const productSpecial = {
    productId,
    name: undefined,
    description: undefined,
    shortName: undefined,
    unitProduct: undefined,
    productPrice: {
      amount: undefined,
      unitAmount: undefined,
      unitProduct: undefined,
    },
  };
  if (productId !== undefined) return productSpecial;
  delete productSpecial.productId;
  return productSpecial;
};

const createProductSpecialRequest = (productSpecial) => {
  const { productPrice } = productSpecial;
  return { ...productSpecial, productPrices: [productPrice] };
};

const itemCompare = (a, b) => {
  if (a.sequence > b.sequence) return -1;

  if (a.sequence < b.sequence) return 1;

  return 0;
};
