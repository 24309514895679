import Vue from "vue";
import VueMasonryWall from "vue-masonry-wall";

export default Vue.extend({
    name: 'Main',
    components: { VueMasonryWall },
    data: function () {
        return { images: [], size: 35 };
    },
    mounted: function () {
        this.initImages();
    },
    methods: {
        initImages() {
            for (let key = 1; key <= this.size; key++) {
                const path = `/Images/${key}.JPG`;
                this.images.push(path);
            }
        }
    }
})