import Vue from "vue";
import { routes } from '../../../config'
import { mapActions, mapGetters } from 'vuex';
import SecurityService from '../../../Services/SecuriyService'
import { PAGES } from '../../../Stores/state'
import { PAGE_PAGES_GETTER, PAGE_CURRENTPAGE_GETTER } from '../../../Stores/getter'
import { PAGES_CREATE_ACTION, PAGES_DELETE_ACTION, PAGES_LOADPAGES_ACTION, PAGES_SETPAGE_ACTION } from '../../../Stores/actions'

export default Vue.extend({
    name: 'Account',
    data: function () {
        return {
            user: {},
            secService: new SecurityService(),
            isShow: false,
            isShowDelete: false,
            pageCreate: {},
            pageDelete: {},
            idRules: [v => !!v || 'Id is required',
            v => !/\s+/.test(v) || 'Id must without space!'],
            requireValue: [v => !!v || 'required']
        }
    },
    methods: {
        async fetchData() {
            const user = await this.secService.getUser();
            const { profile: { email, fullname, nickname } } = user
            this.user = {
                email, fullname, nickname
            };
            this.pageCreate = this.createPageEmpty();
            this.pageDelete = this.createPageEmpty();
            await this[PAGES_LOADPAGES_ACTION]();
        },
        onLogout() {
            this.secService.signOut();
        },
        backToHome() {
            this.$router.push(routes.guest.home);
        },
        isSelect(id) {
            return this.page?.id === id;
        },
        async onCheckPage(id) {
            await this[PAGES_SETPAGE_ACTION](id);
        },
        toggleDialog() {
            if (this.$refs.form !== undefined)
                this.$refs.form.resetValidation()
            this.isShow = !this.isShow;
        },
        toggleDeleteDialog(page) {
            if (page !== null)
                this.pageDelete = page;
            this.isShowDelete = !this.isShowDelete;
        },
        async submitPage() {
            const isValid = this.$refs.form.validate()
            if (!isValid) return;

            await this[PAGES_CREATE_ACTION](this.pageCreate);
            this.toggleDialog();
            this.pageCreate = this.createPageEmpty();
        },
        async submitDeletePage() {
            await this[PAGES_DELETE_ACTION](this.pageDelete.id);
            this.toggleDeleteDialog(null);
            this.pageDelete = this.createPageEmpty();
        },
        createPageEmpty() {
            return {
                id: '',
                name: '',
                token: '',
                verifyToken: ''
            }
        },
        ...mapActions(PAGES, [PAGES_LOADPAGES_ACTION, PAGES_SETPAGE_ACTION, PAGES_CREATE_ACTION, PAGES_DELETE_ACTION]),
    },
    computed: {
        ...mapGetters(PAGES, {
            pages: PAGE_PAGES_GETTER,
            page: PAGE_CURRENTPAGE_GETTER
        })
    },
    mounted: async function () {
        await this.fetchData();
    }
})