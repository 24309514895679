import Vue from "vue";

export default Vue.component("confirm-dialog", {
  props: ["method"],
  data: function() {
    return {
      isShow: false,
      dialog: {
        title: "",
        content: "",
      },
    };
  },
  mounted() {
    // Emits on mount
    this.emitInterface();
  },
  methods: {
    onSave() {
      this.isShow = false;
      const result = { ...this.dialog.data, isSuccess: true };
      this.method(result);
    },
    onClose() {
      this.isShow = false;
      const result = { ...this.dialog.data, isSuccess: false };
      this.method(result);
    },
    onShowDialog(val) {
      this.isShow = true;
      this.dialog = val;
    },
    emitInterface() {
      this.$emit("interface", {
        onShow: (val) => this.onShowDialog(val),
      });
    },
  },
});
