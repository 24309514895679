import Admin from "./Pages/Admin/index";
import AdminHome from "./Pages/Admin/Home/Home";
import EventLogs from "./Pages/Admin/EventLogs/EventLogs";
import ConfigSystem from "./Pages/Admin/ConfigSystem/ConfigSystem";
import Account from "./Pages/Admin/Account/Account";
import Post from "./Pages/Admin/Post/Post";
import Product from "./Pages/Admin/Product/product";
import ProductImage from "./Pages/Admin/ProductImage/index";
import ProductSpecial from "./Pages/Admin/ProductSpecial/index";

import Home from "./Pages/Home/Home";
import Main from "./Pages/Home/Main/Main";
import About from "./Pages/Home/About/About";
import Forbidden from "./Pages/Home/Oidc/Forbidden.vue";
import CallBack from "./Pages/Home/Oidc/callback.vue";
import Silent from "./Pages/Home/Oidc/silent.vue";

import VueRouter from "vue-router";
import Vue from "vue";

import Mgr from "./Services/SecuriyService";

Vue.use(VueRouter);
let mgr = new Mgr();

const routes = [
  {
    path: "/",
    name: "Guest",
    component: Home,
    children: [
      { path: "", name: "Home Guest", component: Main },
      { path: "Forbidden", name: "Forbidden", component: Forbidden },
      { path: "callback", name: "Home Guest", component: CallBack },
      { path: "silent", name: "Silent", component: Silent },
      { path: "about", name: "About", component: About },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "Profile",
        meta: { requiresAuth: true },
        component: Account,
      },
      {
        path: "log",
        name: "Logs page",
        meta: { requiresAuth: true },
        component: EventLogs,
      },
      {
        path: "user",
        name: "User",
        meta: { requiresAuth: true },
        component: AdminHome,
      },
      {
        path: "post",
        name: "Post",
        meta: { requiresAuth: true },
        component: Post,
      },
      {
        path: "configSystem",
        name: "Config system page",
        meta: { requiresAuth: true },
        component: ConfigSystem,
      },
      {
        path: "product",
        name: "Product",
        meta: { requiresAuth: true },
        component: Product,
      },
      {
        path: "productImage",
        name: "ProductImage",
        meta: { requiresAuth: true },
        component: ProductImage,
      },
      {
        path: "productImage",
        name: "ProductImage",
        meta: { requiresAuth: true },
        component: ProductImage,
        children: [
          {
            path: ":productId",
            name: "ProductImage",
            meta: { requiresAuth: true },
            component: ProductImage,
          },
        ],
      },
      {
        path: "productSpecial",
        name: "ProductSpecial",
        meta: { requiresAuth: true },
        component: ProductSpecial,
      },
      {
        path: "productSpecial",
        name: "ProductSpecial",
        meta: { requiresAuth: true },
        component: ProductSpecial,
        children: [
          {
            path: ":productId",
            name: "ProductSpecial",
            meta: { requiresAuth: true },
            component: ProductImage,
          },
        ],
      },
    ],
  },
];

const routers = new VueRouter({
  routes,
});

routers.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth) {
    mgr.getRole().then(
      (sucess) => {
        if (to.meta.role == sucess) {
          next();
        } else {
          next("/forbidden");
        }
      },
      (err) => {
        console.log(err);
      }
    );
  } else {
    next();
  }
});

export default routers;
