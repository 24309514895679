export const AUTHORITY_URL = "https://idp.mnlifeblog.com";
// export const AUTHORITY_URL = "http://localhost:5002";

export const SPA_URL = "https://fb.mnlifeblog.com";
// export const SPA_URL = "http://localhost:8080";

// export const URL_BASE = "http://localhost:5000";
export const URL_BASE = "https://www.mnlifeblog.com";

export const URL_SOC_SERVICE = `${URL_BASE}/soc`;

export const URL_SOC_SERVICE_IMAGES = `${URL_BASE}/ImagesSOC`;

export const routes = {
  guest: {
    home: "/",
    about: "/about",
    forbidden: "/forbidden",
    callback: "/callback",
    silent: "/silent",
  },
  admin: {
    home: "/admin/",
    log: "/admin/log",
    config: "/admin/configSystem",
    user: "/admin/user",
    post: "/admin/post",
    product: "/admin/product",
    productSpecial: "/admin/productSpecial",
    productImage: "/admin/productImage",
  },
};
