import Vue from "vue";
import { routes } from "../../config";

export default Vue.extend({
  name: "AppHeader",
  data: function() {
    return {
      itemHeaders: [
        {
          name: "Profile",
          icon: "mdi-account",
          path: routes.admin.home,
          color: "amber",
        },
        {
          name: "Post",
          icon: "mdi-post",
          path: routes.admin.post,
          color: "pink darken-1",
        },
        {
          name: "Users",
          icon: "mdi-television-play",
          path: routes.admin.user,
          color: "teal",
        },
        {
          name: "ConfigSys",
          icon: "mdi-book",
          path: routes.admin.config,
          color: "indigo",
        },
        {
          name: "Product",
          icon: "mdi-cookie",
          path: routes.admin.product,
          color: "light-green",
        },
        {
          name: "Logs",
          icon: "mdi-history",
          path: routes.admin.log,
          color: "blue-grey",
        },
      ],
      item: {
        color: "amber",
        path: routes.admin.home,
      },
    };
  },
  created: function() {
    this.capturePath(this.$route);
  },
  methods: {
    capturePath(route) {
      const paths = route.matched.map((s) => s.path);
      const currenItem = this.itemHeaders.filter((s) => paths.includes(s.path));
      if (currenItem.length > 0) this.item = currenItem[0];
    },
  },
  watch: {
    $route(to) {
      this.capturePath(to);
    },
  },
});
