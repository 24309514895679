import Vue from "vue";
import {
  getSyntaxConfigRefsByPageId,
  getSyntaxConfigProductsBySyntaxId,
  swapSyntaxConfigProducts,
  deleteProduct,
  createProductSimple,
  clearCacheProductTemplate,
  updateProduct,
} from "../../../Services/SocService";
import { mapGetters } from "vuex";
import { PAGES } from "../../../Stores/state";
import { PAGE_CURRENTPAGE_GETTER } from "../../../Stores/getter";
import ProductCard from "../../../components/Product/Index";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";

const maxSize = 1000000;

export default Vue.extend({
  name: "Product",
  components: { ProductCard, ConfirmDialog },
  childInterface: {
    onShow: () => {},
  },
  data: function() {
    return {
      isShow: false,
      configSystem: undefined,
      configSystems: [],
      syntaxConfigProducts: [],
      product: {
        name: undefined,
        description: undefined,
        shortName: undefined,
        pageId: undefined,
        syntaxProductId: undefined,
      },
      formHasErros: false,
      errorMessages: [],
      rules: [(v) => !!v || "Required"],
    };
  },
  created() {
    this.product = createProductDefault();
  },
  mounted: function() {
    this.initData();
  },
  methods: {
    initData() {
      if (!this.hasPage()) return;
      const { id } = this.page;
      getSyntaxConfigRefsByPageId(id, 1, maxSize).then((res) => {
        const {
          data: { data },
        } = res;
        this.configSystems = data;
      });
    },
    hasPage() {
      if (this.page.id === undefined) {
        this.$toasted.show(`Please choose a page`, {
          theme: "bubble",
          position: "top-center",
          duration: 3000,
          fitToScreen: true,
        });
        return false;
      }
      return true;
    },
    onClickSyntaxConfig(item) {
      this.configSystem = item;
      this.product = createProductDefault(this.page.id, this.configSystem.id);
      this.initDataProduct();
    },
    startDrag(evt, item) {
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("itemId", item.id);
    },
    onDrop(evt, item) {
      const requestId = evt.dataTransfer.getData("itemId");
      const targetId = item.id;
      this.onSwap(requestId, targetId);
    },
    onHandlerTop(item, index) {
      const beforeIndex = index - 1;
      const itemSecond = this.syntaxConfigProducts[beforeIndex];
      this.onSwap(item.id, itemSecond.id);
    },
    onSwap(requestId, targetId) {
      swapSyntaxConfigProducts({ requestId, targetId }).then((res) => {
        const { data } = res;
        const itemFirst = data[0];
        const itemSecond = data[1];
        this.syntaxConfigProducts = this.syntaxConfigProducts
          .map((item) => {
            if (item.id == itemFirst.id) item.sequence = itemFirst.sequence;

            if (item.id == itemSecond.id) item.sequence = itemSecond.sequence;

            return item;
          })
          .sort(itemCompare);
      });
    },
    onDeleteProduct(productId) {
      this.onShowDialog(productId);
    },
    onUpdateProduct(item) {
      updateProduct(item.product).then((res) => {
        this.updateProductLocal(res.data);
      });
    },
    onToggleCreate() {
      this.isShow = !this.isShow;
      if (this.isShow === false) {
        this.product = createProductDefault(this.page.id, this.configSystem.id);
        this.resetForm();
      }
    },
    resetForm() {
      this.errorMessages = [];
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset();
      });
    },
    validateForm() {
      this.formHasErrors = false;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
      });
    },
    submit() {
      this.validateForm();
      if (this.formHasErrors) {
        Vue.toasted.show("Form can't submit", {
          theme: "bubble",
          position: "top-center",
          duration: 3000,
          fitToScreen: true,
        });
        return;
      }
      createProductSimple(this.product).then(() => {
        this.initDataProduct();
      });
      this.onToggleCreate();
    },
    initDataProduct() {
      getSyntaxConfigProductsBySyntaxId(this.configSystem.id).then((res) => {
        const {
          data: { data },
        } = res;
        this.syntaxConfigProducts = data;
      });
    },
    onClearCacheProduct() {
      const { id } = this.page;
      const { key } = this.configSystem;
      clearCacheProductTemplate(id, key);
    },
    updateProductLocal(request) {
      for (let item of this.syntaxConfigProducts) {
        const { product } = item;
        if (product.id == request.id) {
          product.name = request.name;
          product.shortName = request.shortName;
          product.description = request.description;
        }
      }
    },
    onDialogHandler(value) {
      const { id, isSuccess } = value;
      if (!isSuccess) return;
      deleteProduct(id).then((res) => {
        const {
          data: { id },
        } = res;
        this.syntaxConfigProducts = this.syntaxConfigProducts.filter(
          (s) => s.product.id !== id
        );
      });
      console.log(id);
    },
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    onShowDialog(id) {
      const dialog = {
        title: "Do you want to delete it?",
        content: `Product: ${id}`,
        data: {
          id: id,
        },
      };
      this.$options.childInterface.onShow(dialog);
    },
  },
  computed: {
    form() {
      return {
        name: this.product.name,
        description: this.product.description,
        shortName: this.product.shortName,
      };
    },
    relativeKeyArr() {
      if (
        this.configSystem === undefined ||
        this.configSystem.relativeKeys === null ||
        this.configSystem.relativeKeys === ""
      )
        return;

      return this.configSystem.relativeKeys.split(",");
    },
    ...mapGetters(PAGES, { page: PAGE_CURRENTPAGE_GETTER }),
  },
});

const itemCompare = (a, b) => {
  if (a.sequence > b.sequence) return -1;

  if (a.sequence < b.sequence) return 1;

  return 0;
};

const createProductDefault = (
  pageId = undefined,
  syntaxConfigId = undefined
) => ({
  name: undefined,
  description: undefined,
  shortName: undefined,
  pageId,
  syntaxConfigId,
});
