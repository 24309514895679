import Vue from "vue";
import Vuex from "vuex";
import pages from "./modules/pages";
import { PAGES } from "./state";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    [PAGES]: pages,
  },
  strict: debug,
});
