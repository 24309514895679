<template>
  <h1>Forbidden</h1>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Forbidden",
});
</script>
