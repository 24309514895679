import Vue from "vue";
import { routes } from "../../config";
import ConfirmDialog from "../../components/Dialogs/ConfirmDialog";

export default Vue.extend({
  name: "Home",
  components: { ConfirmDialog },
  childInterface: {
    onShow: () => {},
  },
  data: function() {
    return {
      routes,
      isHome: true,
    };
  },
  methods: {
    onRedirect() {
      if (this.isHome) {
        this.toggleHome();
        this.$router.push(routes.guest.about);
      } else {
        this.toggleHome();
        this.$router.push(routes.guest.home);
      }
    },
    toggleHome() {
      this.isHome = !this.isHome;
    },
    onDialogHandler(value) {
      console.log(value);
    },
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    onShowDialog() {
      const dialog = {
        title: "test title",
        content: "content title",
        data: {
          id: 1,
        },
      };
      this.$options.childInterface.onShow(dialog);
    },
  },
});
