import Vue from "vue";
import { getPostsByPage, deletePost, updatePost, sendMessageAllUsersOfPage } from '../../../Services/SocService'
import { mapGetters } from 'vuex'
import { PAGES } from '../../../Stores/state'
import { PAGE_CURRENTPAGE_GETTER } from '../../../Stores/getter'

export default Vue.extend({
  name: 'Post',
  data: function () {
    return {
      headers: [
        {
          value: 'createdUtc',
          text: 'CreateAt',
          sortable: false,
          width: '20%'
        },
        {
          value: 'pageName',
          text: 'Page',
          sortable: false,
          width: '20%'
        },
        {
          value: 'content',
          text: 'Content',
          sortable: false
        },
        {
          value: 'action',
          text: 'Action',
          sortable: false,
          width: '20%'
        }
      ],
      pageIndex: 1,
      pageSize: 5,
      items: [],
      totalCount: 0,
      isLoading: true,
      post: {},
      isShow: false,
      isShowMessage: false,
      messageContent: ''
    }
  },
  methods: {
    async fetchData() {
      if (this.pageSize === -1)
        this.pageSize = this.totalCount;
      const { data: { data, paged } } = await getPostsByPage(this.pageIndex, this.pageSize, this.page.id);
      this.items = data;
      this.totalCount = paged.totalCount;
      this.isLoading = false;
    },
    onUpdatePost(post) {
      this.post = post;
      this.toggleDialog();
    },
    async onDeletePost(post) {
      await deletePost(post.postId);
      await this.fetchData();
      const message = `Deleted post at ${this.$moment(post.createdUtc).format("dddd, MMMM Do YYYY")} `;
      Vue.toasted.info(message, {
        theme: "bubble",
        position: "top-center",
        duration: 3000,
        fitToScreen: true,
      });
    },
    async onSubmitPost() {
      const { postId, content } = this.post;
      await updatePost(postId, content);
      this.toggleDialog();
      const message = `Deleted post at ${this.$moment(this.post.createdUtc).format("dddd, MMMM Do YYYY")} `;
      Vue.toasted.success(message, {
        theme: "bubble",
        position: "top-center",
        duration: 3000,
        fitToScreen: true,
      });
    },
    toggleDialog() {
      this.isShow = !this.isShow;
    },
    toggleMessageDialog() {
      if (this.page.id === undefined) {
        this.$toasted.show(`Please choose a page`, {
          theme: "bubble",
          position: "top-center",
          duration: 3000,
          fitToScreen: true,
        });
        return
      }

      this.isShowMessage = !this.isShowMessage;
    },
    async onSubmitMessage() {
      await sendMessageAllUsersOfPage(this.page.id, this.messageContent)
      this.messageContent = ''
      this.toggleMessageDialog()
    }
  },
  computed: {
    ...mapGetters(PAGES, { page: PAGE_CURRENTPAGE_GETTER })
  },
  mounted: async function () {
    await this.fetchData();
  },
  watch: {
    pageIndex: async function () {
      await this.fetchData();
    },
    pageSize: async function () {
      await this.fetchData();
    },
    page: async function () {
      await this.fetchData();
    }
  }
})