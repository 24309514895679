<template>
  <h1>Waiting callback</h1>
</template>

<script>
import Oidc from "oidc-client";
import Vue from "vue";
import { routes } from "../../../config";

export default Vue.extend({
  name: "Callback",
  data: function () {
    return {};
  },
  mounted: function () {
    let self = this;
    let mgr = new Oidc.UserManager({
      response_mode: "query",
      userStore: new Oidc.WebStorageStateStore(),
      loadUserInfo: true,
      filterProtocolClaims: true,
    });

    mgr
      .signinRedirectCallback()
      .then(function () {
        self.$router.push(routes.admin.home);
      })
      .catch(function (err) {
        console.log(err);
        self.$router.push(routes.guest.home);
      });
  },
});
</script>
