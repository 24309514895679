import Vue from "vue";
import {
  getProductImagesByProductId,
  deleteProductImage,
  createProductImage,
  toFileFromURL,
  updateProductImage,
} from "../../../Services/SocService";
import { URL_SOC_SERVICE_IMAGES, routes } from "../../../config";
import { dicImageType } from "../../../Shared/Utils/imageHelper";
import {
  DEFAULT_DISPLAY,
  MENU_DISPLAY,
} from "../../../commons/productImageConstant.ts";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";

export default Vue.extend({
  name: "ProductImage",
  components: { ConfirmDialog },
  childInterface: {
    onShow: () => {},
  },
  data: function() {
    return {
      productId: undefined,
      productImages: [],
      headers: headers,
      pageSize: 15,
      pageIndex: 1,
      isOpenDialog: false,
      rules: [(v) => !!v || "Rquired"],
      productImage: {
        id: null,
        name: null,
        isDynamic: false,
        path: null,
        productId: null,
        file: null,
        display: null,
        displayChecked: false,
      },
      product: undefined,
    };
  },
  created() {
    const {
      params: { productId },
    } = this.$route;
    this.productId = productId;
    this.productImage = createProductImageDefault(productId);
  },
  mounted: function() {
    if (!this.hasProduct()) return;
    this.initData();
  },
  methods: {
    hasProduct() {
      if (this.productId === undefined) {
        this.$toasted.show(`Please choose a product`, {
          theme: "bubble",
          position: "top-center",
          duration: 3000,
          fitToScreen: true,
        });
        return false;
      }
      return true;
    },
    initData() {
      getProductImagesByProductId(
        this.productId,
        this.pageIndex,
        this.pageSize
      ).then((res) => {
        const {
          data: { data },
        } = res;
        this.productImages = data;
      });
    },
    deleteProductImage(item) {
      this.onShowDialog(item);
    },
    async updateProductImage(item) {
      this.productImage = { ...this.productImage, ...item };
      if (!item.isDynamic) {
        const res = await toFileFromURL(item.path);
        const { data } = res;
        const file = new File(
          [data],
          `${item.name}${dicImageType.get(data.type)}`,
          { type: data.type }
        );
        this.productImage.file = file;
      }

      this.productImage.displayChecked = this.displayConvert;
      this.toggleDialog();
    },
    async submitCreate() {
      if (!this.isValidCreateProductImage) return;
      this.rebuildProductImage();
      if (this.productImage.id !== undefined && this.productImage.id !== null) {
        await updateProductImage(this.productImage);
      } else {
        await createProductImage(this.productImage);
      }
      this.initData();

      this.toggleDialog();
    },
    toggleDialog() {
      this.isOpenDialog = !this.isOpenDialog;
    },
    renderImageURL(item) {
      if (item.isDynamic) return item.path;

      const url = `${URL_SOC_SERVICE_IMAGES}/${item.path}`;
      return url;
    },
    showCreateProductImage() {
      this.productImage = createProductImageDefault(this.productId);
      this.toggleDialog();
    },
    backNavigate() {
      const { $router } = this;
      if (window.history.length > 2) $router.back();
      $router.push(routes.admin.home);
    },
    rebuildProductImage() {
      this.productImage.display = this.displayCheckConvert;
    },
    displayConvertHandler(value) {
      if (value === MENU_DISPLAY) return true;
      if (value === DEFAULT_DISPLAY) return false;
      return false;
    },
    onDialogHandler(value) {
      const { id, isSuccess } = value;
      if (!isSuccess) return;
      deleteProductImage(id).then(() => {
        this.productImages = this.productImages.filter((s) => s.id != id);
      });
    },
    getChildInterface(childInterface) {
      this.$options.childInterface = childInterface;
    },
    onShowDialog(item) {
      const dialog = {
        title: "Do you want to delete it?",
        content: `Product Image: ${item.name}`,
        data: {
          id: item.id,
        },
      };
      this.$options.childInterface.onShow(dialog);
    },
  },
  computed: {
    isValidCreateProductImage() {
      const isValidate =
        this.productImage.name &&
        this.productImage.productId &&
        (this.productImage.path || this.productImage.file);
      return isValidate;
    },
    displayCheckConvert: function() {
      if (this.productImage.displayChecked) return MENU_DISPLAY;
      if (!this.productImage.displayChecked) return DEFAULT_DISPLAY;
      return DEFAULT_DISPLAY;
    },
    displayConvert: function() {
      return this.displayConvertHandler(this.productImage.display);
    },
  },
});

const createProductImageDefault = (productId) => ({
  id: undefined,
  name: undefined,
  path: undefined,
  isDynamic: false,
  productId,
  file: undefined,
  display: DEFAULT_DISPLAY,
  displayChecked: false,
});

const headers = [
  {
    value: "name",
    text: "Name",
  },
  {
    value: "path",
    text: "Preview",
  },
  {
    value: "display",
    text: "Display on menu",
  },
  {
    value: "isDynamic",
    text: "Dynamic",
  },
  {
    value: "action",
    text: "Action",
  },
];
