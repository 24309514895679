import Vue from "vue";
import { getUsersByPage } from '../../../Services/SocService'
import { mapGetters } from 'vuex'
import { PAGES } from '../../../Stores/state'
import { PAGE_CURRENTPAGE_GETTER } from '../../../Stores/getter'

export default Vue.extend({
  name: 'Home',
  data: function () {
    return {
      headers: [
        {
          value: 'name',
          text: 'Name',
          sortable: false
        },
        {
          value: 'firstName',
          text: 'First Name',
          sortable: false
        },
        {
          value: 'lastName',
          text: 'Last Name',
          sortable: false
        },
        {
          value: 'parties.PSID',
          text: 'PSID',
          sortable: false
        }
      ],
      pageIndex: 1,
      pageSize: 5,
      items: [],
      totalCount: 0,
      isLoading: false
    }
  },
  methods: {
    fetchData() {
      let self = this;
      getUsersByPage(this.page.id)
        .then(res => {
          const { data } = res
          this.totalCount = data.length
          data.map(s => {
            if (s.name === '' || s.name === null || s.name.length === 0)
              s.name = 'Tên chưa cập nhật'
            return s;
          })
          self.items = data;
        })
    }

  },
  computed: {
    ...mapGetters(PAGES, {
      page: PAGE_CURRENTPAGE_GETTER
    })
  },
  mounted: function () {
    this.fetchData();
  },
  watch: {
    pageSize: function () {
      this.fetchData();
    },
    pageIndex: function () {
      this.fetchData();
    },
    page: function () {
      this.fetchData();
    }
  }
})