import Vue from "vue";
import Log from '../../../components/Log/Log'
import { getEventLogs } from '../../../Services/SocService'

export default Vue.extend({
  name: 'EventLogs',
  components: { Log },
  data: function () {
    return {
      dataConfig: config,
      Events: [],
      itemCount: 0,
      isLoading: true,
      pageIndex: 1,
      pageSize: 5
    }
  },
  created: function () {
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (this.pageSize === -1)
        this.pageSize = this.itemCount;
      getEventLogs(this.pageIndex, this.pageSize)
        .then(res => {
          const { data: { data, paged } } = res
          this.Events = data
          this.itemCount = paged.totalCount;
          this.isLoading = false;
        })
    }
  },
  watch: {
    pageSize: function () {
      this.fetchData();
    },
    pageIndex: function () {
      this.fetchData();
    }
  }
})

const config = [
  {
    value: 'id',
    text: 'Id',
    sortable: false
  },
  {
    value: 'logId',
    text: 'LogId',
    sortable: false
  },
  {
    value: 'from',
    text: 'From',
    sortable: false
  },
  {
    value: 'to',
    text: 'To',
    width: '30%',
    sortable: false
  },
  {
    value: 'method',
    text: 'Method',
    sortable: false
  },
  {
    value: 'body',
    text: 'Body',
    width: '30%',
    sortable: false
  },
  {
    value: 'statusCode',
    text: 'Status',
    width: '5%',
    sortable: false
  }
]
