import Vue from "vue";
import Mgr from '../../../Services/SecuriyService'
import { routes } from '../../../config'

const user = new Mgr();
export default Vue.extend({
    name: 'Main',
    data: function () {
        return {
            isSignedIn: false,
            user: {}
        }
    },
    mounted: async function () {
        this.isSignedIn = await user.getIsSigned();
        if (this.isSignedIn)
            this.user = await user.getProfile();
    },
    methods: {
        onSignin() {
            user.signIn();
        },
        gotoAdmin() {
            this.$router.push(routes.admin.home);
        }
    }
})