import Vue from "vue";
import { URL_SOC_SERVICE_IMAGES } from "../../config";

export default Vue.component('product-special-card', {
  props: {
    item: undefined,
    index: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {

    };
  },
  computed: {
    renderImageURL() {
      if (this.item.product.images === null || this.item.product.images === undefined) return;
      const { images } = this.item.product
      const image = images.pop();
      if (image === undefined) return;
      if (image.isDynamic)
        return image.path;

      return `${URL_SOC_SERVICE_IMAGES}/${image.path}`;
    },
    productSpecial() {
      return this.item.productSpecial;
    },
    productPrice() {
      const { productPrices } = this.item.productSpecial;
      return productPrices === null ? null : productPrices.pop();
    },
    displayPrice() {
      if (this.productPrice === null) return null;
      const { amount, unitAmount } = this.productPrice;
      const { shortName } = this.item.product;
      const shortNameSpecial = this.productSpecial.shortName;
      return `${shortName} ${shortNameSpecial}-${amount}${unitAmount}`;
    },
    isDisplayBtnTop() {
      return this.index === 0 ? false : true;
    }
  }
})