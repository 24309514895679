import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import Toasted from "vue-toasted";
import Moment from "vue-moment";

Vue.use(Toasted);
Vue.use(Moment);
Vue.use(Vuetify);

export default new Vuetify({});
