<template>
  <h1>Silent</h1>
</template>

<script>
import Oidc from "oidc-client";
import Vue from "vue";

export default Vue.extend({
  name: "Silent",
  mounted: function () {
    new Oidc.UserManager().signinSilentCallback();
  },
});
</script>
