import { getPages, createPage, deletePage } from "../../Services/SocService";
import { PAGES_SETPAGE_ACTION, PAGES_LOADPAGES_ACTION, PAGES_CREATE_ACTION, PAGES_DELETE_ACTION } from "../actions";
import { PAGES_CREATE_MUTATION, PAGES_DELETE_MUTATION, PAGES_SETPAGES_MUTATION, PAGES_SETPAGE_MUTATION } from "../mutations";
import { PAGE_PAGES_GETTER, PAGE_CURRENTPAGE_GETTER } from "../getter";

const store = {
  namespaced: true,
  state: () => ({
    items: [],
    currentPage: {},
  }),
  getters: {
    [PAGE_PAGES_GETTER]: (state) => state.items,
    [PAGE_CURRENTPAGE_GETTER]: (state) => state.currentPage,
  },
  actions: {
    async [PAGES_LOADPAGES_ACTION]({ commit }) {
      const {
        data: { data },
      } = await getPages();
      commit(PAGES_SETPAGES_MUTATION, data);
    },
    [PAGES_SETPAGE_ACTION]({ commit, state }, id) {
      const page = state.items.filter((s) => s.id == id);
      commit(PAGES_SETPAGE_MUTATION, page[0]);
    },
    async [PAGES_CREATE_ACTION]({ commit }, page) {
      const { data } = await createPage(page)
      commit(PAGES_CREATE_MUTATION, data)
    },
    async [PAGES_DELETE_ACTION]({ commit }, pageId) {
      const { data } = await deletePage(pageId)
      commit(PAGES_DELETE_MUTATION, data)
    }
  },
  mutations: {
    [PAGES_SETPAGES_MUTATION](state, items) {
      state.items = items;
    },
    [PAGES_SETPAGE_MUTATION](state, page) {
      state.currentPage = page;
    },
    [PAGES_CREATE_MUTATION](state, page) {
      state.items = [page, ...state.items]
    },
    [PAGES_DELETE_MUTATION](state, page) {
      const { items } = state;
      const pages = items.filter(item => item.id !== page.id)
      state.items = pages;
    }
  },
};

export default { ...store };
